<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { getAction, postAction, putAction } from '../../../command/netTool'

export default {
  name: 'travel',
  data() {
    return {
      ...api.command.getState(),
      inData: [],
      recordsData: []
    }
  },
  mounted() {
    api.command.getList
      .call(this, {
        url: '/base/farmTravelCalendar/page',
        current: 1
      })
      .then(result => {
        this.recordsData = result.data
      })

    getAction('/api/base/farmTravelMonth/getList').then(e => {
      if (e.code == 200) {
        let data = e.data.map(e => {
          return {
            text: e.monthName,
            value: e.month
          }
        })
        this.inData = data
      } else {
        this.$message.error(e.msg)
      }
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '活动标题',
          key: 'activityTitle',
          type: 'input'
        },
        {
          label: '',
          key: 'a1',
          type: 'text'
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'linkMonth',
          title: '月份',
          align: 'left',
          filters: this.inData,
          filterMultiple: false,
          customRender: (text, records) => {
            return (
              <div class="product-order-item-copy">
                <img src={records.activityBackgroundUrl} />
                <div>{text}</div>
              </div>
            )
          }
          //   onExport: (text, records) => {
          //     return `${records.activityBackgroundUrl} / ${text}`
          //   }
        },
        {
          dataIndex: 'activityTitle',
          title: '活动标题',
          align: 'left'
        },
        {
          dataIndex: 'activitySubTitle',
          title: '活动副标题',
          align: 'left'
        },
        {
          dataIndex: 'farmLabelDataVOS',
          title: '标签',
          align: 'left',
          getData: records => records.farmLabelDataVOS.slice(0, 6).map(e => e.labelName),
          type: 'tagGroup',
          onExport: records => {
            let arr = records.map(e => {
              return e.labelName
            })
            return arr.toString()
          }
        },
        {
          dataIndex: 'upDown',
          title: '状态',
          type: 'badge',
          filters: [
            {
              text: '下架',
              value: '1'
            },
            {
              text: '上架',
              value: '0'
            }
          ],
          onExport: records => {
            return ['上架', '下架'][records]
          },
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '上架' : '下架',
              color: data == 0 ? 'green' : 'red'
            }
          },
          filterMultiple: false
        },
        {
          dataIndex: 'a9',
          title: '操作',
          type: 'buttonGroup',
          typeData: ({ records }) => {
            return [
              {
                name: records.upDown == 0 ? '下架' : '上架',
                onClick: () => {
                  api.command.edit.call(this, {
                    url: `/base/farmTravelCalendar/${records.upDown == 0 ? 'down' : 'up'}/${records.id}`
                  })
                }
              },
              {
                name: '编辑',
                onClick: () => this.$router.push('/marketingCenter/travelDetail?id=' + records.id)
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `/base/farmTravelCalendar/${records.id}`
                  })
              }
            ]
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/marketingCenter/travelDetail')
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
        // showRowSelect={false}
      />
    )
  }
}
</script>
